import Price from 'types/Price';
import { FormattedMessage } from 'utils/intl';
import formatAmount, { NumberFormatOptions } from 'utils/formatAmount';

import messages from './AmountFormetter.messages';

type Props = {
  price: Price;
  type?: 'net' | 'gross';
  options?: NumberFormatOptions;
};

const AmountFormatter = ({ price, type, options }: Props): JSX.Element => {
  const message = type ? messages[type] : undefined;

  return message ? (
    <FormattedMessage
      {...message}
      values={{
        price: formatAmount(price, options),
      }}
    />
  ) : (
    <>{formatAmount(price, options)}</>
  );
};

export default AmountFormatter;
